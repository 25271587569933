export default class Category {
	id: number;
	idParent: number | null;
	sortOrder: number;
	name: string;
	hauserAddition: string | null;
	childs: Category[];

	constructor() {
		this.id = 0;
		this.idParent = null;
		this.sortOrder = 0;
		this.name = '';
		this.hauserAddition = '';
		this.childs = [];
	}

	translateApi(json: any): void {
		this.id = Number(json.id);
		this.idParent = json.idParent;
		this.sortOrder = Number(json.sortOrder);
		this.name = String(json.name);
		this.hauserAddition = json.hauserAddition;
	}
}
