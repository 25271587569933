import React from 'react';
import Observer from '../observerstore/Observer';
import CategoryCollection from '../models/CategoryCollection';
import SidebarComponent from '../componentsFunctional/Sidebar';
import Store from '../observerstore/Store';
const $ = Store.data;

interface Props {
	activeCategory: number;
}

interface State {
	categoryCollection: CategoryCollection;
}

export default class Sidebar extends Observer<Props, State> {
	notAllowedCategories: number[] = [];

	constructor(props: Props) {
		super(props);
		this.state = {
			categoryCollection: $.categoryCollection,
		};
	}

	componentDidMount() {
		Store.attach(this);
		this.readApiCategoryCollection();
	}

	async readApiCategoryCollection() {
		await $.categoryCollection.readApi2();
		Store.notify();
	}

	update() {
		this.setStateNStore({
			categoryCollection: $.categoryCollection,
		});
	}

	render() {
		return (
			<>
				<code className="component_info">{this.constructor.name}</code>
				<SidebarComponent
					activeCategoryId={this.props.activeCategory}
					allCategories={this.state.categoryCollection.allCategories}
				/>
			</>
		);
	}
}
