import { APIURL } from "../constants/urls";
import Picture from "../interfaces/Picture";

export default class Letter {
	id: number = 0;
	name: string = '';
	beschrieb: string = '';

	translateApi(json: any) {
		this.id = Number(json.bri_id);
		this.name = json.bri_name;
		this.beschrieb = json.bri_beschrieb;
	}

	getPicturePath(): Picture {
		const bildpfad = `${APIURL}/image/letters/CHE/${this.name}/${this.name}_`
		return {
			original: bildpfad + 'original.jpg',
			thumbnail: bildpfad + 'Xx100.jpg',
			small: bildpfad + 'Xx200.jpg',
			medium: bildpfad + 'Xx600.jpg',
			large: bildpfad + 'Xx1600.jpg',
		};
	}
}
