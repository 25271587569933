export default function stylePrice(value: string | null): string {
	if (value === null) {
		console.log("null");
		return "– –";
	}
	if (Number(value) === 0) {
		return "LP";
	}
	let rappen: string[] = String(value).split(".");
	let temp: RegExpMatchArray | null = rappen[0].match(
		/\d{1,3}(?=(\d{3})*$)/g
	);
	let newValue: string = "";
	if (temp !== null) {
		for (let i in temp) {
			newValue = newValue + (i === "0" ? "" : "'") + temp[i];
		}
	}
	if (rappen[1] === undefined) {
		newValue = newValue + ".00";
	} else {
	if (rappen[1].length === 1) {
		newValue = newValue + "." + rappen[1] + 0;
	} else {
		newValue = newValue + "." + rappen[1];
	}
	}
	return newValue;
}
