export const BASISURL = "http://192.168.100.236/cms";


// TEST1 Web
//export const APIURL = "https://api-test.rj-shop.ch";
//export const REQUESTURL = `${APIURL}/`;

// TEST2 Docker
//export const APIURL = "http://localhost/api/public";
//export const REQUESTURL = `${APIURL}/`;

// Firestorm
export const APIURL = "https://rj-api.nathanaelerdin.ch";
export const REQUESTURL = `${APIURL}/`;

// rj_api_2022 lokal
//export const APIURL = "http://localhost";
//export const REQUESTURL = `${APIURL}/`;

// SCHARF
//export const APIURL = "https://api.rj-shop.ch";
//export const REQUESTURL = `${APIURL}/`;
