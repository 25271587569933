import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import React from 'react';
import Row from 'react-bootstrap/Row';
import { RouteComponentProps } from 'react-router';
import Pagina from '../components/Pagina';
import Store from '../observerstore/Store';
import Observer from '../observerstore/Observer';
import Sidebar from '../components/Sidebar';
import BASISCATEGORIES from '../constants/basisCategories';
import GroupCard from '../components/GroupCard';
import CategoryCollection from '../models/CategoryCollection';
import CategoryDropdown from '../components/CategoryDropdown';
import LayoutButtons from '../components/LayoutButtons';
import SearchBar from '../components/SearchBar';
import SidebarButton from '../components/SidebarButton';
import SortButtons from '../components/SortButtons';
import GroupCollection from '../models/GroupCollection';
import SearchBanner from '../components/SearchBanner';
const $ = Store.data;

interface MatchParams {
	group?: string;
	anzahl?: string;
	seite?: string;
	level?: string;
}
interface Props extends RouteComponentProps<MatchParams> {}
interface State {
	groupCollection: GroupCollection;
	categoryCollection: CategoryCollection;
	suchbegriff: string;
	showSidebar: boolean;
}

export default class Overview extends Observer<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			groupCollection: new GroupCollection(),
			categoryCollection: new CategoryCollection(),
			suchbegriff: '',
			showSidebar: true,
		};
	}

	initialize() {
		// Standardparameter
		let anzahl: number = 15;
		let seite: number = 1;
		let categories: number[] = BASISCATEGORIES;
		let level: number = 1;

		// Falls Parameter mitgegeben wurden
		if (this.props.match.params.group !== undefined) {
			if (Number(this.props.match.params.group) === 0) {
				if ($.url.categories.length > 1) {
					categories = $.url.categories;
				}
			} else {
				categories = [Number(this.props.match.params.group)];
			}
		}
		if (this.props.match.params.anzahl !== undefined) {
			anzahl = Number(this.props.match.params.anzahl);
		}
		if (this.props.match.params.seite !== undefined) {
			seite = Number(this.props.match.params.seite);
		}
		if (this.props.match.params.level !== undefined) {
			level = Number(this.props.match.params.level);
		}

		Object.assign($.url, {
			anzahl: anzahl,
			seite: seite,
			categories: categories,
			level: level,
		});

		this.readProducts();
		this.scrollTop();
	}

	readProducts() {
		this.setState({
			groupCollection: new GroupCollection(),
		});
		$.groupCollection.readApi(
			Store,
			$.url.categories,
			$.url.anzahl,
			$.url.seite,
			$.url.level,
			$.url.sortAttribute,
			$.url.sortOrder,
			$.url.searchTerm,
			$.url.searchType
		);
	}

	componentDidMount() {
		Store.attach(this);
		this.initialize();
	}

	componentDidUpdate(prevProps: any) {
		if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
			this.initialize();
		}
	}

	update() {
		this.setStateNStoreForce({
			groupCollection: $.groupCollection,
			categoryCollection: $.categoryCollection,
			showSidebar: $.showSidebar,
		});
	}

	scrollTop() {
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	}

	render() {
		return (
			<>
				<Container>
					<code className="component_info">{this.constructor.name}</code>
					<Row>
						<Col
							xs={12}
							sm={5}
							md={5}
							lg={4}
							xl={3}
							className={this.state.showSidebar ? '' : 'unsichtbar'}
						>
							<Sidebar activeCategory={Number(this.props.match.params.group)} />
						</Col>
						<Col
							sm={this.state.showSidebar ? 7 : 12}
							lg={this.state.showSidebar ? 8 : 12}
							xl={this.state.showSidebar ? 9 : 12}
						>
							<Row>
								<Col sm={6} md={6} lg={6} xl={6}>
									<SidebarButton />
								</Col>
								<Col xs={12} sm={6} md={6} lg={6} xl={6} className="text-right">
									{(false) && (
										<>
											<CategoryDropdown />
											&nbsp;
										</>
									)}
									<SortButtons />
									&nbsp;
									<LayoutButtons />
								</Col>
							</Row>
							<div style={{ marginTop: '10px' }}>
								<SearchBar />
							</div>
							<SearchBanner />
							{this.state.groupCollection.collection.map((g, i) => {
								return <GroupCard key={i} group={g} />;
							})}
							<Pagina />
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}
