import { REQUESTURL } from '../constants/urls';
import Group from '../models/Group';
import CategoryCollection from '../models/CategoryCollection';

export default class GroupCollection {
	collection: Group[];
	count: number;
	localStorageKey: string;
	categoryCollection: CategoryCollection;

	constructor() {
		this.collection = [];
		this.count = 0;
		this.localStorageKey = 'token';
		this.categoryCollection = new CategoryCollection();
	}

	async readApi(
		Store: any,
		categories: number[],
		anzahl: number,
		seite: number,
		level: number,
		sortAttribute: string,
		sortOrder: string,
		searchTerm: string,
		searchType: string
	) {
		await this.categoryCollection.readApi2();

		if (searchTerm === '') searchTerm = '0';
		if (searchType === 'number') searchType = 'nummer';
		if (searchType === 'all') searchType = '0';
		if (searchType === 'year') searchType = 'year';
		
		let categoryArray = this.categoryCollection.getCategoryChildNumbers(categories[0])
		if(!categoryArray.length) {
			categoryArray = categories
		}

		const url = `${REQUESTURL}product-list/all`;
		try {
			const response = await fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
				body: JSON.stringify({
					searchTerm: searchTerm,
					searchType: searchType,
					categoryIds: categoryArray,
					page: seite,
					pageCount: anzahl,
					sortColumn: sortAttribute,
					sortOrder: sortOrder,
				}),
			});
			const json = await response.json();
			await this.translateApi(json, level, categories);
			Store.notify();
		} catch (err) {
			console.error(err);
		}
	}

	async translateApi(json: any, level: number, categories: number[]) {
		this.collection = [];
		for (let i of json.products) {
			let group: Group = new Group();
			await group.translateApi(i, level, categories, this.categoryCollection);
			this.collection.push(group);
		}
		this.count = Number(json.count);
	}
}
