import Observer from "../observerstore/Observer";
import Dropdown from "react-bootstrap/Dropdown";
import CategoryCollection from "../models/CategoryCollection";
import React from "react";
import * as T from "../language/categorydropdown.json";
import iconSquare from "../img/iconSquare.svg";
import { Language } from "../types/Language";
import iconCheckSquare from "../img/iconCheckSquare.svg";
import Store from "../observerstore/Store";
const $ = Store.data;

interface Props {}
interface State {
	categoryCollection: CategoryCollection;
	inactive: number[];
	show: boolean;
	language: Language;
}

export default class CategoryDropdown extends Observer<Props, State> {
	dropdown: any;
	constructor(props: Props) {
		super(props);
		this.state = {
			categoryCollection: $.categoryCollection,
			inactive: [79, 78, 75, 62, 61, 58, 57],
			show: false,
			language: $.language,
		};
		this.dropdown = React.createRef();
		this.toggle = this.toggle.bind(this);
		this.click = this.click.bind(this);
	}

	public update(): void {
		this.setStateNStoreForce({
			categoryCollection: $.categoryCollection,
			language: $.language,
		});
	}

	private getWantedCategories(): number[] {
		let good = $.categoryCollection.getIdArray();
		let bad = this.state.inactive;
		return good.filter(function (el) {
			return !bad.includes(el);
		});
	}

	private async click(e: any): Promise<void> {
		let id: number = Number(e.target.id);
		console.log(id);
		if (this.state.inactive.includes(id)) {
			let newInactive = this.state.inactive.filter(function (
				value: number
			) {
				return value !== id;
			});
			await this.setState({
				inactive: newInactive,
			});
		} else {
			await this.setState({
				inactive: [...this.state.inactive, id],
			});
		}
		this.search();
	}

	private search(): void {
		$.url.categories = this.getWantedCategories();
		$.groupCollection.readApi(
			Store,
			$.url.categories,
			$.url.anzahl,
			1,
			$.url.level,
			$.url.sortAttribute,
			$.url.sortOrder,
			$.url.searchTerm,
			$.url.searchType
		);
		this.firstPageJump();
	}

	private firstPageJump(): void {
		let url: string = "#/overview/";
		url += $.url.katId + "/";
		url += $.url.anzahl + "/";
		url += 1;
		window.location.href = url;
	}

	private toggle(isOpen: any, e: any, metadata: any): void {
		this.setState({ show: isOpen || metadata.source === "select" });
	}

	public render() {
		return (
			<React.Fragment>
				<code className="component_info">{this.constructor.name}</code>

				<Dropdown
					style={{ display: "inline-block" }}
					show={this.state.show}
					onToggle={this.toggle}
				>
					<Dropdown.Toggle variant="outline-primary" size="sm">
						{T.kategorieAuswahl[this.state.language]}
					</Dropdown.Toggle>

					<Dropdown.Menu show={this.state.show}>
						{this.state.categoryCollection.collection.map(
							(c, i) => (
								<Dropdown.Item
									key={i}
									id={String(c.id)}
									onClick={this.click}
									style={{
										paddingLeft: "10px",
										paddingRight: "15px",
									}}
								>
									{!this.state.inactive.includes(c.id) && (
										<img
											alt="iconCheckSquare"
											id={String(c.id)}
											src={iconCheckSquare}
											style={{
												verticalAlign: "sub",
												paddingRight: "7px",
											}}
										/>
									)}
									{this.state.inactive.includes(c.id) && (
										<img
											alt="iconSquare"
											id={String(c.id)}
											src={iconSquare}
											style={{
												verticalAlign: "sub",
												paddingRight: "7px",
											}}
										/>
									)}
									<label
										className="form-check-label"
										id={String(c.id)}
									>
										{c.name}
									</label>
								</Dropdown.Item>
							)
						)}
					</Dropdown.Menu>
				</Dropdown>
			</React.Fragment>
		);
	}
}
