import { APIURL } from "../constants/urls";
import Price from "../interfaces/Price";
import LetterCollection from "../models/LetterCollection";
import Picture from "../interfaces/Picture";
import stylePrice from "../functions/stylePrice";
import { Language } from "../types/Language";
import { REQUESTURL } from "../constants/urls";

export default class ParentProduct {
	id: number = 0;
	hauser_zusatz: string = "";
	kategorie_id: number = 0;
	znr: string = "";
	znrZahl: string = "";
	satznr: string = "";
	title_de: string = "";
	name_de: string = "";
	name_fr: string = "";
	jahr: string = "";
	ausgabetag: string = "";
	beschrieb_de: string = "";
	beschrieb_fr: string = "";
	langbeschrieb_de: string = "";
	langbeschrieb_fr: string = "";
	letters: LetterCollection = new LetterCollection();
	wertstufe: string = "";
	wertstufe_waehrung: string = "";
	bild: string = "";
	picturePaths: Picture = {
		original: "",
		thumbnail: "",
		small: "",
		medium: "",
		large: "",
	};
	localStorageKey: string = "SourceProduct";
	prices: Price = {
		gestempelt: "",
		postfrisch: "",
		gestempeltViererblock: "",
		ungestempeltViererblock: "",
		postfrischViererblock: "",
		brief: "",
		fdc: "",
		fdcDe: "",
		fdcIt: "",
		fdcFr: "",
		ungestempelt: "",
		vollstempelEt: "",
		vollstempelEtDe: "",
		vollstempelEtFr: "",
		vollstempelEtIt: "",
		viererblockEt: "",
		viererblockEtDe: "",
		viererblockEtFr: "",
		viererblockEtIt: "",
		paarUngestempelt: "",
		paarGestempelt: "",
		paarBrief: "",
	};
	gestempelt: boolean = false;
	vollstempel: boolean = false;
	ungestempelt: boolean = false;
	postfrisch: boolean = false;
	gestempeltViererblock: boolean = false;
	ungestempeltViererblock: boolean = false;
	postfrischViererblock: boolean = false;
	viererBlock: boolean = false;
	brief: boolean = false;
	fdc: boolean = false;
	ersttag: boolean = false;
	preis: string = "";
	katalogwert: string = "";
	picturePath: string = `${APIURL}/image/referenzdatenbank/default.jpg`;
	selected: boolean = false;
	gueltigkeitsdauer: string = "";
	farbe_de: string = "";
	farbe_fr: string = "";
	druckauflage: string = "";
	layout: number = 0;
	zusatzinfo_1: string = "";

	public getPicturePaths(): Picture {
		if (this.bild) {
			const bildpfad = APIURL + this.bild + '_';
			return {
				original: bildpfad + "original.jpg",
				thumbnail: bildpfad + "Xx100.jpg",
				small: bildpfad + "Xx200.jpg",
				medium: bildpfad + "Xx600.jpg",
				large: bildpfad + "Xx1600.jpg",
			};
		} else {
			return {
				original: `${APIURL}/image/products/default.jpg`,
				thumbnail: `${APIURL}/image/products/default.jpg`,
				small: `${APIURL}/image/products/default.jpg`,
				medium: `${APIURL}/image/products/default.jpg`,
				large: `${APIURL}/image/products/default.jpg`,
			};
		}
	}

	public cleanZnr(input: string): string {
		let output: string = input;
		output = output.replaceAll(" ", "_");
		output = output.replaceAll("(", "-");
		output = output.replaceAll(")", "-");
		return output;
	}

	public toPicturePath(directory: string, file: string): Picture {
		const bildpfad = `${APIURL}/image/referenzdatenbank/CHE/${directory}/${file}_`;
		return {
			original: bildpfad + "original.jpg",
			thumbnail: bildpfad + "Xx100.jpg",
			small: bildpfad + "Xx200.jpg",
			medium: bildpfad + "Xx600.jpg",
			large: bildpfad + "Xx1600.jpg",
		};
	}

	public setPrice<K extends keyof Price>(input: K): void {
		this.resetZustand();
		switch (input) {
			case "gestempelt":
				this.gestempelt = true;
				break;
			case "ungestempelt":
				this.ungestempelt = true;
				break;
			case "postfrisch":
				this.postfrisch = true;
				break;
			case "gestempeltViererblock":
				this.gestempelt = true;
				this.viererBlock = true;
				break;
			case "postfrischViererblock":
				this.postfrisch = true;
				this.viererBlock = true;
				break;
			case "brief":
				this.brief = true;
				break;
			case "fdc":
				this.ersttag = true;
				break;
			case "vollstempelEt":
				this.vollstempel = true;
				this.ersttag = true;
				break;
			case "viererblockEt":
				this.viererBlock = true;
				this.ersttag = true;
				break;
			case "paarUngestempelt":
				break;
			case "paarGestempelt":
				break;
			case "paarBrief":
				break;
			default:
				break;
		}
	}

	private resetZustand(): void {
		this.gestempelt = false;
		this.ungestempelt = false;
		this.postfrisch = false;
		this.viererBlock = false;
		this.brief = false;
		this.ersttag = false;
		this.vollstempel = false;
	}

	public readApi(Store: any, id: number): void {
		const _this = this;
		const token = localStorage.getItem("token");

		fetch(`${REQUESTURL}api/readProductSource`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({ id: id }),
		})
			.then(function (response) {
				if (response.ok) return response.json();
				console.error("Die Rückgabe ist nicht ok");
				throw new Error("Irgendwas konnte nicht geladen werden");
			})
			.then(function (json) {
				_this.translateApi(json);
			})
			.catch(function (err) {
				console.error(`error: ${err}`);
			});
	}

	public updateApi() {
		const _this = this;
		fetch(REQUESTURL + "update_product", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(_this),
		})
			.then(function (response) {
				if (response.ok) return response.json();
				console.error("Die Rückgabe ist nicht ok");
				throw new Error("Irgendwas konnte nicht geladen werden");
			})
			.catch(function (err) {
				// Hier Fehlerbehandlung
				console.error(`error: ${err}`);
			});
	}

	public translateApi(json: any) {
		this.id = Number(json.pro_id);
		this.hauser_zusatz = json.pro_hauser_zusatz;
		this.kategorie_id = Number(json.pro_kategorie);
		this.znr = json.pro_znr;
		this.znrZahl = json.pro_znr_zahl;
		this.satznr = json.pro_satznr;
		this.title_de = json.pro_titel_de;
		this.name_de = json.pro_name_de;
		this.name_fr = json.pro_name_fr;
		this.jahr = json.pro_jahr;
		this.ausgabetag = json.pro_ausgabetag;
		this.beschrieb_de = json.pro_beschrieb_de;
		this.beschrieb_fr = json.pro_beschrieb_fr;
		this.langbeschrieb_de = json.pro_langbeschrieb_de;
		this.langbeschrieb_fr = json.pro_langbeschrieb_fr;
		this.letters.translateApi(json.briefe);
		this.wertstufe_waehrung = json.pro_wertstufe_waehrung;
		if (this.wertstufe_waehrung === "Franken") {
			this.wertstufe = String(
				Number(json.pro_wertstufe.replace(",", ".")) * 100
			);
		} else {
			this.wertstufe = json.pro_wertstufe;
		}
		this.bild = json.picture_exists;
		this.prices.gestempelt = json.pro_gestempelt;
		this.prices.ungestempelt = json.pro_ungestempelt;
		this.prices.postfrisch = json.pro_postfrisch;
		this.prices.gestempeltViererblock = json.pro_block;
		this.prices.ungestempeltViererblock = json.pro_block_ungestempelt;
		this.prices.postfrischViererblock = json.pro_block_postfrisch;
		this.prices.brief = json.pro_brief;
		this.prices.fdc = json.pro_fdc;
		this.prices.fdcDe = json.pro_fdc_de;
		this.prices.fdcFr = json.pro_fdc_fr;
		this.prices.fdcIt = json.pro_fdc_it;
		this.prices.ungestempelt = json.pro_ungestempelt;
		this.prices.vollstempelEt = json.pro_vollstempel_et;
		this.prices.vollstempelEtDe = json.pro_vollstempel_de;
		this.prices.vollstempelEtFr = json.pro_vollstempel_fr;
		this.prices.vollstempelEtIt = json.pro_vollstempel_it;
		this.prices.viererblockEt = json.pro_et_block;
		this.prices.viererblockEtDe = json.pro_et_block_de;
		this.prices.viererblockEtFr = json.pro_et_block_fr;
		this.prices.viererblockEtIt = json.pro_et_block_it;
		this.prices.paarUngestempelt = json.pro_paar_ungestempelt;
		this.prices.paarGestempelt = json.pro_paar_gestempelt;
		this.prices.paarBrief = json.pro_paar_brief;
		this.picturePaths = this.getPicturePaths();
		this.gueltigkeitsdauer = json.pro_gueltigkeitsdauer;
		this.farbe_de = json.pro_farbe_de;
		this.farbe_fr = json.pro_farbe_fr;
		this.druckauflage = json.pro_druckauflage;
		this.layout = Number(json.pro_layout_preis);
		this.zusatzinfo_1 = json.pro_zusatzinfo_01;
	}

	public getPrice<K extends keyof Price>(input: K): string {
		let value = this.prices[input];
		return stylePrice(String(value));
	}

	public true_or_false(input: any) {
		if (Number(input) === 0) {
			return false;
		}
		return true;
	}

	public hasLetters(): boolean {
		return !(this.letters.collection.length === 0);
	}

	public getName<K extends keyof this>(language: Language): string {
		let propertyName: K = ("name_" + language) as K;
		let output = this[propertyName];
		if (!output) return this.name_de; // Default value
		return String(output);
	}

	public getLangbeschrieb<K extends keyof this>(language: Language): string {
		let propertyName: K = ("langbeschrieb_" + language) as K;
		let output = this[propertyName];
		if (!output) return this.langbeschrieb_de; // Default value
		return String(output);
	}

	public getColor<K extends keyof this>(language: Language): string {
		let propertyName: K = ("farbe_" + language) as K;
		let output = this[propertyName];
		if (!output) return this.farbe_de; // Default value
		return String(output);
	}

	public getBeschrieb<K extends keyof this>(language: Language): string {
		let propertyName: K = ("beschrieb_" + language) as K;
		let output = this[propertyName];
		if (!output) return this.beschrieb_de; // Default value
		return String(output);
	}

	public getKurzbeschrieb<K extends keyof this>(language: Language): string {
		let prefix = "";
		let suffix = "";
		if (this.wertstufe !== "" && this.wertstufe_waehrung !== "") {
			prefix = this.wertstufe + " " + this.wertstufe_waehrung;
		}
		if (this.getBeschrieb(language) && prefix) {
			suffix = ", ";
		}
		if (this.getBeschrieb(language)) {
			suffix += this.getBeschrieb(language);
		}
		if (this.getColor(language)) {
			suffix += ", " + this.getColor(language);
		}
		return prefix + suffix;
	}

	createLocalStorage() {
		localStorage.setItem(this.localStorageKey, JSON.stringify(this));
	}
}
